import React from "react";
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { useLocale } from "@context/locale";

import Breadcrumbs from "@components/Breadcrumbs";
import Button from "@components/Button";
import ActualPromotions from "@components/ActualPromotions";
import Seo from "@components/Seo";

import BlogAside from "@page_components/blog/BlogAside";
import PromotionBanner from "@page_components/blog/PromotionBanner";

function BlogPost({ data }) {
  const { t, lang } = useLocale();
  const seo = data?.wpPost?.seo;
  const { title, content, date, featuredImage } = data?.wpPost;
  const recent_posts = data.recentPosts.nodes.filter(
    (category) => category.language.slug === lang
  );
  const categories = data.wpPost.categories.nodes;
  const image = getImage(featuredImage?.node?.gatsbyImage);
  const promotions = data.promotion_data.nodes.map((item) => {
    return {
      title: lang === "en" ? item.promotion.titleEn : item.promotion.title,
      description:
        lang === "en"
          ? item.promotion.descriptionEn
          : item.promotion.description,
      link: lang === "en" ? item.promotion.linkEn : item.promotion.link,
      photo: item.promotion.photo,
    };
  });

  const format_date = new Date(date).toLocaleString("pl", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const breadcrumbs_data = [
    { name: "Blog", href: lang === "en" ? `/en/blog` : `/blog` },
    { name: title },
  ];

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="blog-post">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />
          <h1>{title}</h1>
          <main>
            <div className="blog-post__post">
              <div className="blog-post__image">
                {!!image && (
                  <GatsbyImage
                    image={image}
                    alt={featuredImage?.node?.altText || ""}
                  />
                )}
              </div>
              <div className="blog-post__info">
                <p className="blog-post__date">{format_date}</p>

                <p className="blog-post__category">{categories[0].name}</p>
              </div>

              <div
                className="blog-post__content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
              <Button href={lang === "en" ? "/en/blog" : "/blog"}>
                {t("Wszystkie wpisy")}
              </Button>
            </div>
            <div className="blog-post__aside">
              <BlogAside
                recent_articles={recent_posts}
                article_categories={categories}
              />
              <PromotionBanner data={promotions} />
            </div>
          </main>
        </div>
        <ActualPromotions />
      </div>
    </>
  );
}

export default BlogPost;

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      featuredImage {
        node {
          gatsbyImage(width: 643, height: 583, quality: 100, layout: FULL_WIDTH)
          altText
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      date(formatString: "MM.DD.YYYY")
      title
      content
      seo {
        title
        metaDesc
      }
    }

    recentPosts: allWpPost(
      limit: 5
      filter: { slug: { ne: $slug } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        slug
        title
        language {
          slug
        }
      }
    }

    promotion_data: allWpPromotion(limit: 5) {
      nodes {
        promotion {
          title
          titleEn
          photo {
            publicUrl
          }
          link {
            linkLabel
            linkUrl
          }
          linkEn {
            linkLabel
            linkUrl
          }
          description
          descriptionEn
        }
      }
    }
  }
`;
