import "./styles.scss";

import { Link } from "gatsby";
import React from "react";

import { useLocale } from "@context/locale";

function BlogAside({ recent_articles, article_categories }) {
  const { t, lang } = useLocale();

  return (
    <div className="blog-aside">
      <h3>{t("Ostatnie wpisy")}</h3>
      <ul className="blog-aside__list-links">
        {recent_articles.map((article) => (
          <li className="blog-aside__link">
            <Link
              to={
                lang === "en"
                  ? `/en/blog/${article.slug}`
                  : `/blog/${article.slug}`
              }
            >
              {article.title}
            </Link>
          </li>
        ))}
      </ul>

      <h3>{t("Kategorie")}</h3>
      <ul className="blog-aside__list-categories">
        {article_categories.map((category) => (
          <li className="blog-aside__category">
            <Link
              to={
                lang === "en"
                  ? `/en/blog/${category.slug}/#blogList`
                  : `/blog/${category.slug}/#blogList`
              }
            >
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BlogAside;
