import "./styles.scss";

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { useLocale } from "@context/locale";

import Promotion from "./Promotion";

function ActualPromotions() {
  const { t, lang } = useLocale();
  const data = useStaticQuery(graphql`
    {
      allWpPromotion {
        nodes {
          promotion {
            title
            titleEn
            datetoend
            datetoendEn
            description
            descriptionEn
          }
          title
        }
      }
    }
  `);
  const promotions = data.allWpPromotion.nodes;

  return (
    <div className="actual-promotions">
      <div className="container">
        <h2 className="actual-promotions__title">{t("Aktualne promocje")}</h2>
        <h4 className="actual-promotions__subtitle">
          {t("Sprawdź co dla Ciebie przygotowaliśmy")}
        </h4>

        <div className="actual-promotions__list ">
          {promotions.map((item) => (
            <Promotion
              title={
                lang === "pl" ? item.promotion.title : item.promotion.titleEn
              }
              description={
                lang === "pl"
                  ? item.promotion.description
                  : item.promotion.descriptionEn
              }
              dateToEnd={
                lang === "pl"
                  ? item.promotion.datetoend
                  : item.promotion.datetoendEn
              }
              key={
                lang === "pl" ? item.promotion.title : item.promotion.titleEn
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ActualPromotions;
