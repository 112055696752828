import "./styles.scss";

import React from "react";

import { useLocale } from "@context/locale";

import CalendarIcon from "@icons/calendar.svg";

function Promotion({ title, description, dateToEnd }) {
  const { t, lang } = useLocale();

  const date = new Date(dateToEnd).toLocaleString(lang, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="promotion">
      <h5 className="promotion__title">{title}</h5>
      <div
        className="promotion__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <p className="promotion__date">
        <CalendarIcon className="promotion__date-icon" />
        {`Promocja trwa do:  ${dateToEnd ? date : t("do odwołania")}`}
      </p>
    </div>
  );
}

export default Promotion;
