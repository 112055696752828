import "./styles.scss";

import React from "react";
import Slider from "react-slick";

import Button from "@components/Button";

function PromotionBanner({ data }) {
  const settings_slider = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    swipe: false,

    className: "promotion-banner__sliderr",
  };

  return (
    <div className="promotion-banner">
      <Slider {...settings_slider}>
        {data.map((item) => {
          return (
            <>
              <div className="promotion-banner__photo">
                {/* <GatsbyImage image={photo} /> */}
                <img src={item?.photo?.publicUrl} alt="" srcSet="" />
              </div>
              <main className="promotion-banner__content">
                <h3 className="promotion-banner__title"> {item.title} </h3>
                <p
                  className="promotion-banner__description"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
                <Button
                  className="promotion-banner__button"
                  href={item.link.linkUrl}
                >
                  {item.link.linkLabel}
                </Button>
              </main>
            </>
          );
        })}
      </Slider>
    </div>
  );
}

export default PromotionBanner;
